@import url('https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi+Fun:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500&display=swap');
.plus{
    font-family: 'Plus Jakarta Sans', sans-serif;
}
.bga{
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2e2447+0,1f2029+100 */
background: rgb(2, 12, 27); /* Old browsers */
background: -moz-linear-gradient(top,   rgb(23, 23, 24) 0% rgb(28, 29, 36) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,   rgb(23, 23, 24) 0%,rgb(28, 29, 36) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgb(23, 23, 24) 0%,rgb(28, 29, 36) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#020c1b', endColorstr='#1f2029',GradientType=0 ); /* IE6-9 */

}

.inter{
  font-family: 'Inter', sans-serif;
}
.roboto{
font-family: 'Roboto', sans-serif;
}
.b{
    -webkit-tap-highlight-color: transparent;
}

   .dda{
    font-family: 'Reem Kufi Fun', sans-serif;

   }
   
   
   
   .gsap-3-logo {
   width: 20vw;
   max-width: 150px;
   position: fixed;
   bottom: 15px;
   right: 15px;
   }
   .bl{
    bottom: 0;
    position: fixed;
    left: 50px;
}
.b{
    -webkit-tap-highlight-color: transparent;
}
